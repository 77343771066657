.ProseMirror em,
.ProseMirror i {
    display: block !important;
    font-style: italic !important;
    background-color: #ffebee !important;
    padding: 15px !important;
    margin: 15px 0 !important;
    line-height: 1.6 !important;
    border-radius: 4px !important;
    width: 100% !important;
    box-sizing: border-box !important;
    clear: both !important;
    float: none !important;
}

/* Remove the previous ::before and ::after rules since we don't need them anymore */
.ProseMirror em::before,
.ProseMirror i::before,
.ProseMirror em::after,
.ProseMirror i::after {
    display: none !important;
}

.print-content ul {
  padding-left: 20px;
  margin: 10px 0;
}

.print-content li {
  margin-bottom: 5px;
}

.print-content h2 {
  font-size: 1.5rem;
  margin-bottom: 0.8rem;
}

.print-content h3 {
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
}

@media print {
  .print-content {
    background-color: #fffff0 !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}

.audio-container {
  margin: 20px 0;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
}

.audio-container audio {
  width: 100%;
}

.custom-italic {
  display: block;
  padding: 15px;
  background-color: #ffebee;
  font-style: italic;
  margin: 10px 0;
  line-height: 1.6;
} 